import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import OutsideClickHandler from "react-outside-click-handler";

import { buttonDefault } from "./styleObjects";
import { useCartVisibility } from "../context/cartVisibilityContext";
import { useShopifyRuntime } from "../context/shopifyRunTime";

const container = css({
  position: "fixed",
  zIndex: 5000,
  top: 0,
  right: 0,
  width: 400,
  height: "100vh",
  backgroundColor: "white",
  borderLeft: "1px solid #e5e5e5",
  transform: "translateX(100%)",
  display: "flex",
  flexDirection: "column",
  opacity: 0,
  transition: "transform .2s ease-in-out, opacity .2s ease-out",

  "@media (max-width: 1100px)": {
    width: "35%",
  },

  "@media (max-width: 800px)": {
    width: 300,
  },
  "@media (max-width: 500px)": {
    width: "95%",
  },
});

const header = css({
  borderBottom: "1px solid #e5e5e5",
  padding: "30px 15px 15px 15px",
  order: 1,

  p: {
    fontSize: 22,
    fontWeight: 500,

    "@media (max-width: 1100px)": {
      fontSize: 20,
    },
    "@media (max-width: 800px)": {
      fontSize: 19,
    },
    "@media (max-width: 500px)": {
      fontSize: 17,
    },
    "@media (max-width: 400px)": {
      fontSize: 16,
    },
  },
});

const xIcon = css({
  fontFamily: "Sans-serif",
});

const closeIcon = css({
  position: "absolute",
  top: 8,
  right: 8,
});

const itemsContainer = css({
  padding: "30px 15px 15px 15px",
  flexGrow: 1,
  overflowY: "auto",
  borderBottom: "1px solid #e5e5e5",
  order: 2,

  "@media (max-width: 500px)": {
    order: 3,
  },
});

const emptyText = css({
  textAlign: "center",
  color: "#aba9a9",
  fontVariant: "small-caps",
});

const itemContainer = css({
  display: "flex",
  alignItems: "center",
  marginBottom: 30,
});

const itemImgContainer = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 40,
  height: 50,
  backgroundColor: "#e5e5e5",
});

const itemInfo = css({
  flexGrow: 1,
  margin: "auto 10px",
  display: "flex",
  flexDirection: "column",
});

const itemPrice = css({ textAlign: "right" });

const itemDelete = css({ padding: 5, color: "#aba9a9" });

const checkoutContainer = css({
  padding: "30px 15px 15px 15px",
  borderBottom: "1px solid #e5e5e5",
});

const totalContainer = css({
  display: "flex",
  justifyContent: "space-between",
  fontWeight: 500,
});

const checkoutButton = css({
  fontVariant: "small-caps",
  textAlign: "center",
  backgroundColor: "#e5e5e5",
  borderRadius: 2,
  padding: 7,
  margin: "15px auto 8px auto",
  fontSize: 20,
  order: 3,
  // opacity: 0.3,
  // cursor: "auto",

  "&:hover": { opacity: 0.3 },

  "@media (max-width: 1100px)": {
    fontSize: 18,
    padding: 6,
  },
  "@media (max-width: 800px)": {
    fontSize: 17,
    padding: 5,
  },
  "@media (max-width: 500px)": {
    fontSize: 15,
    padding: 4,
    order: 2,
  },
  "@media (max-width: 400px)": {
    fontSize: 14,
    padding: 3,
  },
});

function Cart({ pageName }) {
  const cart = useCartVisibility();
  const { checkout } = useShopifyRuntime();

  const atShop = pageName === "shop" || pageName === "product";

  if (!atShop) {
    return null;
  }

  if (!checkout.isInit) {
    return null;
  }

  const calcTotalPrice = () => {
    const availableItems = checkout.items.filter(
      (item) => item.availableForSale
    );
    let totalPrice = 0;
    availableItems.forEach((item) => {
      totalPrice += Number(item.price);
    });
    return totalPrice;
  };

  // if (!productsCheckoutProcessed) return null;

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => cart.isOpen && cart.close()}>
        <div
          css={[
            container,
            cart.isOpen && { transform: "translateX(0)", opacity: 1 },
          ]}
        >
          <div css={[header, { order: 1 }]}>
            <p>Your Cart</p>
            <p css={[buttonDefault, closeIcon, xIcon]} onClick={cart.close}>
              x
            </p>
          </div>
          {checkout.numItems ? (
            <div css={[checkoutContainer, { order: 3 }]}>
              <div css={totalContainer}>
                <p>Total</p>
                <p>£{calcTotalPrice()}</p>
              </div>
              <div
                css={[buttonDefault, checkoutButton]}
                onClick={checkout.goToShopifyCheckout}
              >
                checkout
              </div>
            </div>
          ) : null}
          <Items>
            {checkout.mutations.addItem.isLoading ? (
              <div>Adding item...</div>
            ) : null}
            {checkout.mutations.removeItem.isLoading ? (
              <div>Removing item...</div>
            ) : null}
            {!checkout.numItems ? <div css={emptyText}>empty cart</div> : null}
          </Items>
        </div>
      </OutsideClickHandler>
      <div
        css={[
          css({
            zIndex: cart.isOpen ? 4000 : -1,
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            opacity: cart.isOpen ? 0.5 : 0,
            transition: "opacity .2s ease-out",
          }),
        ]}
      />
    </>
  );
}

export default Cart;

const Items = ({ children }) => {
  const { checkout } = useShopifyRuntime();

  return (
    <div css={[itemsContainer, { order: 2 }]}>
      {checkout.items.map((item) => (
        <Item item={item} key={item.shopifyId} />
      ))}
      {children}
    </div>
  );
};

const Item = ({ item }) => {
  const cart = useCartVisibility();
  const { checkout } = useShopifyRuntime();

  return (
    <div css={[itemContainer]} key={item.id}>
      <Link
        css={[
          buttonDefault,
          itemImgContainer,
          !item.availableForSale && { opacity: 0.4 },
        ]}
        to={`/${item.title.replace(" ", "-")}`}
      >
        <img
          src={item.image}
          css={{ width: "100%", height: "100%" }}
          loading="lazy"
          alt=""
        />
      </Link>
      <div css={[itemInfo]}>
        <Link
          css={[buttonDefault, !item.availableForSale && { opacity: 0.4 }]}
          to={`/${item.title.replace(" ", "-")}`}
          onClick={cart.close}
        >
          <p>{item.title}</p>
        </Link>
        <p css={[itemPrice, !item.availableForSale && { opacity: 0.6 }]}>
          {item.availableForSale
            ? `£${item.price.replace(".0", "")}`
            : "Sorry, item sold"}
        </p>
      </div>
      <div css={itemDelete}>
        <p
          css={[buttonDefault, xIcon]}
          onClick={() => checkout.mutations.removeItem.mutate(item.checkoutId)}
        >
          x
        </p>
      </div>
    </div>
  );
};
